import { AggregationApiClient } from "./aggregation-client";
import { ExtendedOfferDto } from "./aggregation-client/service";

type Props = {
  lastId?: string;
  arrivalDate: string;
  departureDate: string;
  cities?: string;
  hotelIds?: string;
  attributeIds?: string;
  adults: number;
  children?: number;
  sortOrder?: string;
  minPrice?: number;
  maxPrice?: number;
  perPage?: number;
};

export const fetchOffers = (p: Props) => {
  const payload: any = {};

  if (p.hotelIds) {
    payload.hotelIds = p.hotelIds;
  }

  return AggregationApiClient.OffersService.offersControllerSearchOffers({
    perPage: 12,
    ...p,
    ...payload,
    language: "en",
    attributes: p.attributeIds,
    arrivalDate: p.arrivalDate,
    departureDate: p.departureDate,
    sortByField: "price",
  })
    .then((data) => {
      if (!data.offers || data.offers.length === 0) {
        return [];
      }

      return data.offers.map((offer) => {
        return {
          ...offer,
          unitGroup: {
            ...offer.unitGroup,
            description: offer.unitGroup?.description?.replace(/(<([^>]+)>)/gi, "") || "",
            shortDescription: offer.unitGroup?.shortDescription?.replace(/(<([^>]+)>)/gi, "") || "",
          },
        } as ExtendedOfferDto;
      });
    })
    .catch(() => [] as ExtendedOfferDto[]);
};
