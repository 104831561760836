import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import { useListProperties } from "../../../api/hooks";
import { CircularProgress, FormControl, MenuItem, Select, SelectProps, useTheme } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedHotelAtom } from "../../../state/search-state";
import { Iconify } from "../../../fe-ui/atoms/Iconify";
import dataTestIds from "../../../utils/data-test-ids";
import { ExtendedHotelDto } from "../../../api/aggregation-client/service";

// ----------------------------------------------------------------------

type Props = {
  size?: SelectProps["size"];
  hotels?: ExtendedHotelDto[];
};

function FormSearchHotelPickerBody({ size, hotels }: Props) {
  const [selectedHotelId, selectHotelId] = useRecoilState(selectedHotelAtom);

  const t = useTranslation();
  const theme = useTheme();

  return (
    <FormControl fullWidth data-ym={"HOTEL_PICKER"}>
      <Iconify
        icon="material-symbols-light:home-pin-outline"
        color={theme.palette.primary.main}
        sx={{
          pointerEvents: "none",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%) translateX(50%)",
        }}
      />
      <Select
        displayEmpty
        size={size}
        placeholder={t("Common_allHotels")}
        variant="outlined"
        label={null}
        sx={{
          pl: 4,
          fontWeight: 600,
          "& fieldset": {
            border: "none",
          },
          "& .MuiSelect-select": {
            py: 1.5,
          },
          "& .MuiInputBase-inputSizeSmall": {
            py: 1,
          },
          "&:hover": {
            backgroundColor: "rgba(145, 158, 171, 0.08)",
          },
        }}
        value={hotels?.find((h) => selectedHotelId === h.id)?.id || ""}
        onChange={(e) => {
          selectHotelId(e.target.value || "");
        }}
        data-testid={dataTestIds.SEARCH.HOTEL_PICKER.BUTTON}
      >
        <MenuItem sx={{ pl: 4 }} value={""} data-testid={dataTestIds.SEARCH.HOTEL_PICKER.MENU_ITEM.ALL_HOTELS}>
          {t("Common_allHotels")}
        </MenuItem>
        {hotels?.map((hotel) => (
          <MenuItem sx={{ pl: 4 }} key={hotel.id} value={hotel.id}>
            {hotel.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function FormSearchHotelPickerDynamic(p: Props) {
  const { data: hotels = [], isLoading } = useListProperties();

  if (isLoading) {
    return <CircularProgress size={20} />;
  }
  return <FormSearchHotelPickerBody {...p} hotels={hotels} />;
}

export default function FormSearchHotelPicker(p: Props) {
  if (p.hotels && p.hotels.length) {
    return <FormSearchHotelPickerBody {...p} hotels={p.hotels} />;
  }
  return <FormSearchHotelPickerDynamic {...p} />;
}
