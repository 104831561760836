import router from "next/router";
import { atom, selector } from "recoil";

export type IDateRangeSerialized = [string | null, string | null];

export const singleOfferDateRangeAtom = atom<IDateRangeSerialized>({
  key: "single-offer/date-range",
  default: [null, null],
  effects: [
    ({ onSet }) => {
      onSet(([arrivalDate, departureDate]) => {
        if (arrivalDate && departureDate && router.query && router.query.departureDate && router.query.arrivalDate) {
          router.replace(
            {
              query: { ...router.query, arrivalDate, departureDate },
            },
            undefined,
            {
              shallow: true,
            }
          );
        }
      });
    },
  ],
});

export const singleOfferDateRangeAvailableSelector = selector({
  key: "single-offer/date-range-is-available",
  get({ get }) {
    const [a, b] = get(singleOfferDateRangeAtom);
    return Boolean(a && b);
  },
});

// export const filterWidgetShownAtom = atom<boolean>({
//   key: "single-offer/widget-shown",
//   default: false
// });
