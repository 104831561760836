import { AggregationApiClient } from "./aggregation-client";

export const fetchAllUnitGroupAttributes = (p?: {
  language: string;
}): Promise<AggregationApiClient.HotelUnitGroupAttributeGroupsDto[]> => {
  return AggregationApiClient.HotelUnitGroupService.hotelUnitGroupControllerFetchHotelUnitGroupAttributes({
    perPage: 99,
    language: p?.language || "ru",
  })
    .then((items) => {
      return items
        .sort((a, b) => a.priority - b.priority)
        .map((item) => {
          return {
            ...item,
            attributes: item.attributes.sort((a, b) => a.priority - b.priority),
          };
        });
    })
    .catch((__err) => {
      return [];
    });
};
