import { BackendApiClient } from "./api-client";

export const fetchUnitGroupPromotionLabels = () => {
  return BackendApiClient.HotelUnitGroupService.hotelUnitGroupControllerFindHotelUnitGroupPromotionLabels({
    perPage: 99,
    page: 0,
    language: "en",
  })
    .then((items) => {
      const mappedItems = items;
      return mappedItems;
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      return [];
    });
};
