import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { dateRangeAtom, searchFullUrlSelector } from "../../../state/search-state";
import { useRouter } from "next/router";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import { useEmbeddedContext } from "../../EmbeddedContext";
import SearchIcon from "./icons/SearchIcon";

type Props = {
  isCompact?: boolean;
  isSheet?: boolean;
  navigateToSearchPage?: boolean;
  onClick?: () => void;
};

export const FormSearchSubmitButton = ({
  isCompact = false,
  isSheet = false,
  navigateToSearchPage,
  onClick,
}: Props) => {
  const { push } = useRouter();

  const [range] = useRecoilState(dateRangeAtom);
  const t = useTranslation();
  const theme = useTheme();
  const { basePath } = useEmbeddedContext();
  // const selectedHotelId = useRecoilValue(selectedHotelAtom);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fullSearchLink = useRecoilValue(searchFullUrlSelector);

  const handleSubmit = useCallback(() => {
    if (navigateToSearchPage) {
      push(((basePath ? basePath : "") + fullSearchLink) as "/search");
    }
    onClick?.();
  }, [range, fullSearchLink]);

  const isReadyToSubmit = true; // JSON.stringify(range) !== JSON.stringify(draftDateRange);

  return (
    <Box
      sx={{
        height: isCompact ? "62px" : isSheet ? "48px" : isMobile ? "64px" : "68px",
        backgroundColor: isCompact ? "transparent" : "white",
        border: isCompact ? `1px solid ${theme.palette.divider}` : undefined,
        borderLeftWidth: isCompact ? 0 : undefined,
        borderTopRightRadius: isCompact ? (isMobile ? undefined : "16px") : isMobile ? undefined : "16px",
        borderBottomLeftRadius: isCompact ? (isMobile ? undefined : undefined) : isMobile ? "16px" : undefined,
        borderBottomRightRadius: isCompact ? (isMobile ? undefined : "16px") : "16px",
        padding: isCompact ? "8px" : isSheet ? undefined : isMobile ? "8px" : "10px 12px",
      }}
    >
      <Button
        fullWidth
        data-ym="search_submit_btn"
        disabled={!isReadyToSubmit}
        onClick={handleSubmit}
        variant={"contained"}
        color={"primary"}
        data-testid="search-button"
        sx={{
          minWidth: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
          borderRadius: "8px",
          height: "100%",
          padding: isCompact ? "11px" : "12px 24px",
        }}
      >
        <SearchIcon width={isCompact ? 24 : 20} height={isCompact ? 24 : 20} />
        {!isCompact && (
          <Typography sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "24px" }}>
            {t("Index_buttonCtaFind")}
          </Typography>
        )}
      </Button>
    </Box>
  );
};
