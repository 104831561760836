import { AggregationApiClient } from "./aggregation-client";
import { CityDto } from "./aggregation-client/service";

export type ValidCity = Required<CityDto>;

type Params = {
  language: string;
  useShowOnSearchFilter?: boolean;
};

const isValidCity = (city: CityDto): city is ValidCity => {
  return city.id != null && city.title != null;
};

const filterCities = (cities: ValidCity[], useShowOnSearchFilter: boolean): ValidCity[] => {
  return cities.filter((city) => isValidCity(city) && (!useShowOnSearchFilter || city.show_on_search));
};

export const fetchCities = async ({ language, useShowOnSearchFilter = false }: Params): Promise<ValidCity[]> => {
  return AggregationApiClient.LocationService.locationControllerFindAllCities({
    language,
    perPage: 99,
  })
    .then((cities) => {
      return filterCities(cities, useShowOnSearchFilter);
    })
    .catch((_err) => {
      return [];
    });
};
