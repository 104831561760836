export function getLocale(): string {
  if (typeof window !== "undefined") {
    if (window["__LOCALE__"]) {
      return window["__LOCALE__"];
    }
  }
  return "ru";
}

export function getCurrency() {
  return "AZN";
}
