// @mui
import { alpha } from "@mui/material/styles";
import { Stack, IconButton } from "@mui/material";
// components
import Iconify from "../Iconify/Iconify";

// ----------------------------------------------------------------------

type Props = {
  name?: string;
  disabledIncrease?: boolean;
  value: number;
  min?: number;
  max?: number;
  disabledDecrease?: boolean;
  buttonTextColor?: string;
  onChange?: (v: number) => void;
};

export function InputCounter({
  name,
  min,
  max,
  value: quantity,
  onChange,
  disabledIncrease,
  disabledDecrease,
  buttonTextColor,
  ...other
}: Props) {
  return (
    <Stack
      flexShrink={0}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        py: 0.5,
        px: 0.75,
        width: 96,
        borderRadius: 1,
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
        userSelect: "none",
      }}
      {...other}
    >
      <IconButton
        size="small"
        color="inherit"
        onClick={() => onChange?.(quantity - 1)}
        disabled={
          disabledDecrease || (min !== undefined ? quantity <= min : false)
        }
        sx={{ color: buttonTextColor ?? "text.secondary" }}
        data-testid={`${name}-minus-button`}
      >
        <Iconify icon="eva:minus-fill" width={16} />
      </IconButton>

      {quantity}

      <IconButton
        size="small"
        color="inherit"
        onClick={() => onChange?.(quantity + 1)}
        disabled={
          disabledIncrease || (max !== undefined ? quantity >= max : false)
        }
        sx={{ color: buttonTextColor ?? "text.secondary" }}
        data-testid={`${name}-plus-button`}
      >
        <Iconify icon="eva:plus-fill" width={16} />
      </IconButton>
    </Stack>
  );
}
