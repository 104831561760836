import axios, { AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
// config

import { enqueueSnackbar } from "notistack";
import { getLocale } from "./getLocale";

const NEXT_PUBLIC_BACKEND_API_ROOT =
  process.env.NEXT_PUBLIC_BACKEND_API_ROOT || "";

// let adapter = new MockAdapter(axios);

// ----------------------------------------------------------------------

const getBackendApiRoot = () => {
  if (typeof window !== "undefined") {
    return (
      window["__ENV__"]?.["NEXT_PUBLIC_BACKEND_API_ROOT"] ||
      NEXT_PUBLIC_BACKEND_API_ROOT
    );
  }
  return NEXT_PUBLIC_BACKEND_API_ROOT;
};

const axiosInstance = axios.create({
  baseURL: getBackendApiRoot(),
}) as AxiosInstance & {
  _mock?: typeof MockAdapter;
  withMock: (o: {
    url: string;
    response: unknown;
    method?: "get" | "post";
  }) => void;
};

axiosInstance.interceptors.request.use((config) => {
  if (typeof window !== "undefined") {
    const language = getLocale();
    if (language) {
      try {
        config.params = {
          ...config.params,
          language, // getRecoil(userSelectedLanguageIdAtom),
        };
      } catch {
        return config;
      }
    }
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.statusCode === 400 && typeof window !== "undefined") {
      enqueueSnackbar(error.message, {
        autoHideDuration: 5000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

// axiosInstance.withMock = ({ response, method, url }) => {
//   if (process.env.NEXT_PUBLIC_APP_ENV === "mock") {
//     // TODO: маппинг на method
//     adapter.onAny(url).reply(200, { data: response });
//   }
// };

export { axiosInstance as request };
