import axios, { AxiosInstance } from "axios";
import { serviceOptions } from "./service";
import * as BackendApiClient from "./service";
import { request } from "../../fe-ui/utils/request";

const instance: AxiosInstance = request;

serviceOptions.axios = instance;

instance.interceptors.request.use(config => {
  //
  return config;
});

export { BackendApiClient };
