import { BackendApiClientNew } from "./api-client-new";
import { OfferDto } from "./api-client-new/service";

type Props = {
  lastId?: string;
  arrivalDate: string;
  departureDate: string;
  hotelId?: string;
  attributeIds?: string;
  language?: string;
  adults: number;
  children?: number;
  perPage?: number;
};

export const fetchFutureOffers = (p: Props) => {
  const payload: any = {};

  return BackendApiClientNew.OffersService.offersControllerGetCustomFutureOffers({
    perPage: 12,
    language: "en",
    ...p,
    ...payload,
    arrivalDate: p.arrivalDate,
    departureDate: p.departureDate,
  })
    .then((offers) => {
      if (!offers || offers.length === 0) {
        return [];
      }

      return offers.map((offer) => {
        return {
          ...offer,
          unitGroup: {
            ...offer.unitGroup,
            description: offer.unitGroup?.description?.replace(/(<([^>]+)>)/gi, "") || "",
            shortDescription: offer.unitGroup?.shortDescription?.replace(/(<([^>]+)>)/gi, "") || "",
          },
        } as OfferDto;
      });
    })
    .catch(() => [] as OfferDto[]);
};
