import { useCallback, useEffect, useMemo, useState } from "react";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { useRecoilState } from "recoil";
import { IDateRangeSerialized, dateRangeAtom } from "../../../../state/search-state";

import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "../../../../fe-ui/hooks/use-translation";
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { fDate } from "../../../../fe-ui/utils/formatTime";
import { ITimeSlicesDto } from "../../../../api/fetchSingleUnitGroupCalendar";
import { singleOfferDateRangeAtom } from "../../../../state/single-offer-state";
import { useSnackbar } from "notistack";
import dataTestIds from "../../../../utils/data-test-ids";
import { useEmbeddedContext } from "../../../EmbeddedContext";
import { ThemeMode } from "../../../../fe-ui/theme/palette";
import { getLocale } from "../../../../fe-ui/utils/getLocale";

type IDateRange = [Dayjs | null, Dayjs | null];

function dateRangeSerializable(v): IDateRangeSerialized {
  const [a, b] = v;
  return [a ? dayjs(a).format("YYYY-MM-DD") : null, b ? dayjs(b).format("YYYY-MM-DD") : null];
}

const formatDateRange = (dateRange: IDateRangeSerialized): string | null => {
  const locale = getLocale();

  if (dateRange[0] == null || dateRange[1] == null) {
    return null;
  }

  const startDate = dayjs(dateRange[0]).locale(locale);
  const startDateDay = startDate.format("DD");
  const startDateMonth = startDate.format("MMMM").slice(0, 3);
  const startDateYear = startDate.year();
  const endDate = dayjs(dateRange[1]).locale(locale);
  const endDateDay = endDate.format("DD");
  const endDateMonth = endDate.format("MMMM").slice(0, 3);
  const endDateYear = endDate.year();

  if (startDate.year() !== endDate.year()) {
    // different years
    return `${startDateDay} ${startDateMonth}, ${startDateYear}-${endDateDay} ${endDateMonth}, ${endDateYear}`;
  } else if (endDate.year() === dayjs().year()) {
    // both current year
    return `${startDateDay} ${startDateMonth}-${endDateDay} ${endDateMonth}`;
  }
  // same years but not current year
  return `${startDateDay} ${startDateMonth}-${endDateDay} ${endDateMonth}, ${endDateYear}`;
};

type DatePickerModalProps = {
  timeSlices?: ITimeSlicesDto | null;
  defaultValue?: IDateRange;
  onClose: () => void;
  onAccept: (a: IDateRange) => void;
  minStayDays?: number;
};

const DatepickerStatic = ({
  calendarsCount = 1,
  onChange,
  onError,
  defaultValue,
  timeSlices,
  minStayDays = 0,
}: {
  onChange?: (v: IDateRange) => void;
  onError?: (v: boolean) => void;
  calendarsCount: 1 | 2;
  defaultValue?: IDateRange;
  timeSlices?: ITimeSlicesDto | null;
  minStayDays?: number;
}) => {
  const t = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<IDateRange>(() => {
    return defaultValue || [null, null];
  });

  useEffect(() => {
    onChange?.(value);
  }, [value]);

  useEffect(() => {
    const [from, to] = value;
    if (from && to) {
      if (minStayDays) {
        if (to.diff(from, "day") < minStayDays) {
          setValue([to, null]);
          enqueueSnackbar(t("booking_min_stay_days_error", { days: minStayDays }), { variant: "error" });
          return onError?.(true);
        }
      }

      let current = from.clone();
      while (current.isBefore(to)) {
        current = current.add(1, "day");

        // range contains disabled dates
        if (timeSlices && timeSlices[current.format("YYYY-MM-DD")]?.available === false) {
          setValue([to, null]);
          return onError?.(true);
        }
      }
    }

    return onError?.(false);
  }, [value, timeSlices, onError, minStayDays]);

  return (
    <div data-testid={dataTestIds.SEARCH.DATE_PICKER.CALENDAR}>
      <StaticDateRangePicker
        sx={{
          "& .Mui-disabled": {
            textDecoration: "line-through",
          },
        }}
        disablePast
        disableDragEditing={false}
        defaultValue={value}
        reduceAnimations
        value={value}
        onChange={(v) => {
          setValue(v);
        }}
        shouldDisableDate={(day, position) => {
          const [arrivalDate] = value;
          const disabledByTimeSlice = timeSlices?.[day.format("YYYY-MM-DD")]?.available === false;

          if (arrivalDate && position === "end") {
            return day.isSame(value[0]) || disabledByTimeSlice;
          }

          return disabledByTimeSlice;
        }}
        localeText={{
          toolbarTitle: t("datepicker_select_daterange_title"),
          cancelButtonLabel: t("cta_cancel"),
          okButtonLabel: t("cta_apply"),
          start: t("cta_start"),
          end: t("cta_end"),
        }}
        slots={{
          actionBar: () => null,
        }}
        calendars={calendarsCount}
      />
    </div>
  );
};

const DatepickerModal = ({ timeSlices, onClose, onAccept, defaultValue, minStayDays }: DatePickerModalProps) => {
  const t = useTranslation();
  const [value, setValue] = useState<IDateRange>(() => {
    return defaultValue || [null, null];
  });
  const [isOpen] = useState(true);

  const [invalidRangeError, setInvalidRangeError] = useState(false);

  const theme = useTheme();
  const { themeMode } = useEmbeddedContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const calendarsCount = isMobile ? 1 : 2;
  const dialogPaddingTop = isMobile ? 0 : 2;

  return (
    <>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: isMobile ? "auto" : 680,
              ...(themeMode === ThemeMode.aztravel
                ? {
                    position: "absolute",
                    top: 100,
                  }
                : {}),
            },
          },
        }}
        slotProps={
          themeMode === ThemeMode.aztravel
            ? {
                backdrop: {
                  sx: {
                    backgroundColor: "rgba(255,255,255,.75)",
                  },
                },
              }
            : undefined
        }
      >
        <DialogContent sx={{ pt: dialogPaddingTop, typography: "body2" }}>
          <DatepickerStatic
            onError={setInvalidRangeError}
            timeSlices={timeSlices}
            defaultValue={defaultValue}
            onChange={setValue}
            calendarsCount={calendarsCount}
            minStayDays={minStayDays}
          />
          {/* {invalidRangeError && (
            <Alert sx={{ mt: 2 }} severity="error">
              {t("errors_invalid_range_disabled_dates")}
            </Alert>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button
            data-ym={"SEARCH_DATEPICKER_CANCEL"}
            data-testid="datepicker-cancel-button"
            variant="text"
            color="inherit"
            onClick={onClose}
          >
            {t("cta_cancel")}
          </Button>
          <Button
            data-ym={"SEARCH_DATEPICKER_APPLY"}
            disabled={invalidRangeError}
            data-testid="datepicker-submit-button"
            onClick={() => onAccept(value)}
            color="primary"
            variant="contained"
          >
            {t("cta_apply")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

type Props = {
  defaultValue?: IDateRange;
  dateFormat?: "default" | "full";
  context: "single" | "search";
  isCompact?: boolean;
  isSheet?: boolean;
  isFirstItem?: boolean;
  noIcon?: boolean;
} & {
  readOnly?: boolean;
  mode?: "button";
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
  noDatesLabel?: string;
} & {
  timeSlices?: ITimeSlicesDto | null;
  minStayDays?: number;
};

export const FormSearchDatePickerV2 = ({
  timeSlices,
  // size = "large",
  readOnly,
  // mode = "button",
  dateFormat = "default",
  variant = "text",
  context,
  isCompact = false,
  isSheet = false,
  isFirstItem = false,
  minStayDays,
}: Props) => {
  const [dateRangeSaved, setDateRange] = useRecoilState(
    context === "search" ? dateRangeAtom : singleOfferDateRangeAtom
  );
  const computedDefaultValue = useMemo<IDateRange>(() => {
    const [a, b] = dateRangeSaved;
    return [a ? dayjs(a) : null, b ? dayjs(b) : null];
  }, [dateRangeSaved]);
  const t = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [arrivalDate, departureDate] = dateRangeSaved;

  const format = dateFormat === "full" ? "DD MMM YYYY" : "DD MMM";

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const handleAccept = useCallback((newDateRange: IDateRange) => {
    handleClose();
    //
    setDateRange(dateRangeSerializable(newDateRange));
  }, []);

  const dateRangeStr = formatDateRange(dateRangeSaved);

  return (
    <>
      {open && (
        <DatepickerModal
          minStayDays={minStayDays}
          timeSlices={timeSlices}
          defaultValue={computedDefaultValue}
          onAccept={handleAccept}
          onClose={handleClose}
        />
      )}
      <Box
        sx={{
          backgroundColor: isCompact ? "transparent" : isSheet ? theme.palette.background.neutral : "white",
          border: isCompact ? `1px solid ${theme.palette.divider}` : undefined,
          borderLeftWidth: isCompact ? 0 : undefined,
          ...(isSheet && { borderRadius: "8px" }),
          ...(!isSheet &&
            isFirstItem && {
              borderTopLeftRadius: isCompact ? (isMobile ? undefined : "16px") : "16px",
              borderTopRightRadius: isCompact ? (isMobile ? undefined : undefined) : isMobile ? "16px" : undefined,
              borderBottomLeftRadius: isCompact ? (isMobile ? undefined : "16px") : isMobile ? undefined : "16px",
            }),
          height: isCompact ? "62px" : isMobile || isSheet ? "56px" : "68px",
          whiteSpace: "nowrap",
        }}
      >
        <Button
          fullWidth
          disabled={readOnly}
          sx={{
            height: "100%",
            borderRadius: 0,
            ...(isSheet && { borderRadius: "8px" }),
            ...(!isSheet &&
              isFirstItem && {
                borderTopLeftRadius: isCompact ? (isMobile ? undefined : "16px") : "16px",
                borderTopRightRadius: isCompact ? (isMobile ? undefined : undefined) : isMobile ? "16px" : undefined,
                borderBottomLeftRadius: isCompact ? (isMobile ? undefined : "16px") : isMobile ? undefined : "16px",
              }),
            color: readOnly ? "#212B36 !important" : "inherit",
            padding: isCompact ? "19px 16px" : isMobile || isSheet ? "8px 16px" : "12px 16px",
          }}
          disableRipple
          color="inherit"
          variant={variant}
          onClick={handleOpen}
          data-testid={dataTestIds.SEARCH.DATE_PICKER.BUTTON}
          data-ym={"SEARCH_DATEPICKER"}
        >
          {isCompact && (
            <Typography align="right" sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "24px" }}>
              {dateRangeStr ?? t("datepicker_select_daterange_title")}
            </Typography>
          )}
          {!isCompact && (
            <Stack
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: isMobile || isSheet ? "12px" : "28px",
              }}
            >
              <Stack sx={{ display: "flex", flexDirection: "col", flex: 1 }}>
                <Typography
                  align="left"
                  sx={{ color: "text.secondary", fontWeight: 600, fontSize: "12px", lineHeight: "20px" }}
                >
                  {t("search_form_arrival_date_label")}
                </Typography>
                <Typography
                  align="left"
                  sx={{
                    fontWeight: 600,
                    fontSize: isMobile || isSheet ? "14px" : "16px",
                    lineHeight: isMobile || isSheet ? "20px" : "24px",
                  }}
                >
                  {arrivalDate ? fDate(arrivalDate, format, { hideYearWhenCurrentYear: true }) : "–"}
                </Typography>
              </Stack>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Divider
                  orientation="vertical"
                  sx={{ borderRightWidth: "1px", height: "44px", transform: "rotate(10deg)" }}
                />
              </Box>
              <Stack sx={{ display: "flex", flexDirection: "col", flex: 1 }}>
                <Typography
                  align="right"
                  sx={{ color: "text.secondary", fontWeight: 600, fontSize: "12px", lineHeight: "20px" }}
                >
                  {t("search_form_departure_date_label")}
                </Typography>
                <Typography
                  align="right"
                  sx={{
                    fontWeight: 600,
                    fontSize: isMobile || isSheet ? "14px" : "16px",
                    lineHeight: isMobile || isSheet ? "20px" : "24px",
                  }}
                >
                  {departureDate ? fDate(departureDate, format, { hideYearWhenCurrentYear: true }) : "–"}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Button>
      </Box>
    </>
  );
};

// https://stackoverflow.com/questions/72069782/how-to-disable-certain-days-and-dates-in-mui-react-datepicker-based-on-a-json-ar
