import { AggregationApiClient } from "./aggregation-client";

export interface ExtendedHotelUnitGroupDtoWithLinks extends AggregationApiClient.ExtendedHotelUnitGroupDto {
  link: string;
  hotelLink: string;
}

export const fetchSingleUnitGroupById = (
  id: string,
  { language } = { language: "en" }
): Promise<ExtendedHotelUnitGroupDtoWithLinks | null> => {
  return AggregationApiClient.HotelUnitGroupService.hotelUnitGroupControllerGetUnitGroup({
    id,
    language,
  })
    .then((h) => {
      return {
        ...h,
        ratePlanId: h.ratePlanId || "",
        description: h.description || "",
        shortDescription: h.shortDescription || "",
        link: `/unit/${h.id}`,
        hotelLink: `/hotel/${h.hotelId}`,
      };
    })
    .catch(() => null);
};
